/* LINE-HEIGHTS */
$line-height-single: 1;
$line-height-compact: 1.15;
$line-height-base: 1.5;
$line-height-headline: 1.1;

/* SPACING */
$space-1: 4px;
$space-2: 8px;
$space-3: 16px;
$space-4: 24px;
$space-5: 32px;
$space-6: 40px;
$space-7: 80px;
$space-8: 96px;
$space-9: 130px;
$space-gutter: min(6vw, 115px);

/* METRICS */
$metric-nano: 4px;
$metric-micro: 8px;
$metric-xsmall: 16px;
$metric-small: 32px;
$metric-medium: 48px;
$metric-large: 56px;
$metric-xlarge: 72px;
