/* COLORS */
$color-black: #231f20;
$color-black80: #4f4c4d;
$color-black70: #656262;
$color-grey100: #87888b;
$color-grey80: #ababad;
$color-grey50: #aeaeaf;
$color-grey40: #d1d2d4;
$color-grey30: #e2e2e4;
$color-grey20: #f1f1f2;
$color-grey10: #f7f7f8;
$color-white: #ffffff;
$color-imercoOrangeDark: #9f5457;
$color-brand60: #f8a14d;
$color-brand70: #f8ad62;
$color-brownDark: #af8a6a;
$color-brownMedium: #d0bea4;
$color-brownLight: #ebe3db;
$color-brownUltraLight: #fcfaf9;
$color-blueDark: #00264d;
$color-blueMedium: #0059b3;
$color-blueLight: #519fed;
$color-blueUltraLight: #b6d9ff;
$color-greenDark: #2d4734;
$color-greenMedium: #5f8f82;
$color-greenLight: #77ccb5;
$color-greenUltraLight: #c3f0de;
$color-orangeDark: #f04d22;
$color-orangeMedium: #f68a20;
$color-orangeLight: #f7c18c;
$color-orangeUltraLight: #fce1cf;
$color-yellowDark: #ffde69;
$color-yellowMedium: #fff18a;
$color-yellowLight: #fff8ba;
$color-yellowUltraLight: #fffbdc;
$color-redDark: #54001d;
$color-redMedium: #d71e24;
$color-redLight: #e85562;
$color-redUltraLight: #ffdddf;
$color-primary: #f68a20;
$color-secondary: #f7c18c;
$color-headerColor: #ffffff;
$color-headerTextColor: #231f20;
$color-headerSearchInputBorderColor: #ebe3db;
$color-grey90: #525151;
$color-grey70: #928f90;
