@use '~/theme/variables/index.scss' as theme;

.swatchesContainer {
    position: absolute;
    left: theme.$space-4;
    bottom: theme.$space-4;
    max-width: 50px;
    height: theme.$space-4;
    display: flex;
    align-items: center;

    .swatchItem {
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        width: theme.$space-3;
        height: theme.$space-3;
        left: 10px;
    }

    .swatchItem:first-of-type {
        left: 0;
        z-index: 1;
    }

    .swatchItem:nth-of-type(2) {
        left: 10px;
        z-index: 2;
    }

    .swatchItem:nth-of-type(3) {
        left: 20px;
        z-index: 3;
    }

    .swatchMore {
        color: theme.$color-black;
        fill: theme.$color-black;
        width: 8px;
        height: 8px;
        position: absolute;
        left: 40px;

        path {
            fill: theme.$color-black;
            stroke-width: 4;
        }
    }
}
