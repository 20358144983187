// Define media query mixins

@mixin sm {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin max {
    @media (min-width: 1920px) {
        @content;
    }
}

// Define custom media feature mixins
@mixin motionOK {
    @media (prefers-reduced-motion: no-preference) {
        @content;
    }
}

@mixin darkMode {
    @media (prefers-color-scheme: dark) {
        @content;
    }
}
