/* FONT SIZES */
$font-size-exs: 10px;
$font-size-2xs: 12px;
$font-size-xs: 14px;
$font-size-sm: 15px;
$font-size-md: 18px;
$font-size-lg: 20px;
$font-size-xl: 25px;
$font-size-2xl: 30px;
$font-size-3xl: 35px;
$font-size-4xl: 40px;
$font-size-5xl: 45px;
$font-size-6xl: 50px;
$font-size-7xl: 55px;
